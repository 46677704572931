<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"/>
  </div>
</template>

<script>
export default {
  name: 'good-table',
  data(){
    return {
      columns: [
        {
          label: 'Player',
          field: 'player',
        },
        {
          label: 'Score',
          field: 'score',
          type: 'number',
        },
        {
          label: 'Plays',
          field: 'plays'
        },
        {
          label: 'Wins',
          field: 'wins'
        },
        {
          label: 'Wins percent',
          field: 'wins_percent',
          type: 'percentage'
        }
      ]
    };
  },
  props: {
    rows: {
      type: Array
    },
  }
};
</script>