<template>
  <div>
    <b-container class="game-view">
      <b-row>
          <b-col class="game-col" cols="12" sm="6" lg="6" v-for="game in games" v-bind:key="game.boardgame">
            <img class="boardgame_image" :src="game.img" :alt="game.name" />
            <Game
              v-bind:name="game.boardgame"
              v-bind:data="game.playerscores">
            </Game>
          </b-col>        

      </b-row>
    </b-container>

  </div>
</template>

<script>
import axios from 'axios'
import Game from './Game.vue'

export default {
  name: 'game-view',
  components: {
    Game
  },
    data() {
        return {    
            games: []
        };
    },
    props: {
      date: {
          type: String,
          default: ""
      },
      groupid: {
          type: String
      }
    },
    watch: { 
      date: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData()
        }
      },
      groupid: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData()
        }
      }
    },
    created: function() {     
      this.getData()
    },
    methods : {
      getData(){
        this.games = []
        if(this.date != "" && this.date != 0 && this.groupid != "" && this.groupid != 0) {
          axios.get("https://isdragen.dk/php/getboardsbestplayers.php?date=" + this.date + "&group=" + this.groupid).then(result => {
            this.games = Object.values(result.data)
          })
        } else if(this.date != "" && this.date != 0) {
          axios.get("https://isdragen.dk/php/getboardsbestplayers.php?date=" + this.date).then(result => {
            this.games = Object.values(result.data)
          })
        } else if(this.groupid != "" && this.groupid != 0) {
          axios.get("https://isdragen.dk/php/getboardsbestplayers.php?group=" + this.groupid).then(result => {
            this.games = Object.values(result.data)
          })
        }
        else {
          axios.get("https://isdragen.dk/php/getboardsbestplayers.php").then(result => {
            this.games = Object.values(result.data)
          })
        }
      }
    }  
};

</script>

<style scoped>
  .game-view {
    margin: auto;
    max-width: 95%;
  }
.boardgame_image {
  height: 10rem;
}

.game-col {
  margin-top: 3rem;
}
</style>