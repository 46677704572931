<template>
  <div class="highscore">
    <GoodTable v-bind:rows="rows" />
  </div>
</template>

<script>
import GoodTable from './GoodTable.vue'
import axios from 'axios'

export default {
  name: 'Highscore-component',
  components: {
    GoodTable
  },
    data() {
        return {
            rows: []
        };
    },
    props: {
      date: {
          type: String,
          default: ""
      },
      groupid: {
          type: String
      }
    },
    watch: { 
      date: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData()
        }
      },
      groupid: function(newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData()
        }
      }
    },
    created: function() {             
      this.getData()
    },
    methods : {
      getData(){
        if(this.date != "" && this.date != 0 && this.groupid != "" && this.groupid != 0) {
          axios.get("https://isdragen.dk/php/gettopplayers.php?date=" + this.date + "&group=" + this.groupid).then(result => {
            this.rows = Object.values(result.data)
          })  
        } else if(this.date != "" && this.date != 0) {
          axios.get("https://isdragen.dk/php/gettopplayers.php?date=" + this.date).then(result => {
            this.rows = Object.values(result.data)
          })  
        } else if(this.groupid != "" && this.groupid != 0) {
          axios.get("https://isdragen.dk/php/gettopplayers.php?group=" + this.groupid).then(result => {
            this.rows = Object.values(result.data)
          })  
        }
        else {
          axios.get("https://isdragen.dk/php/gettopplayers.php").then(result => {
            this.rows = Object.values(result.data)
          })
        }
      }
    }  
};

</script>

<style scoped>
  .highscore {
    padding: 0 5rem;
  }
</style>