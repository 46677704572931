<template>
    <div>
    <Bar  
       v-bind:label="label"
       v-bind:data="scores"
       v-bind:labels="playernames"
       ></Bar>
    </div>
  </template>
  
  <script>
  import Bar from './Bar.vue'
  
  export default {
    name: 'game-view',
    components: {
      Bar
    },
    data() {
        return {
            scores: [],
            playernames: [],
            chartData: null,
            labels: [],
            label: null,
            backgroundColor: '#f87979',
            highscoreData: null,
            rows: [],
            games: []
        };
    },
    props: {
        data: {
            type: Array,
            require: true
        },
        name: {
            type: String
        }

    },
      methods : {
  
      },
      created: function() {     
        this.playernames = []
        this.scores = []
        var counter = 0
        this.data.forEach(element => {
            this.playernames[counter] = element.name + "(" + element.plays + ") [" + element.wins + "]"
            this.scores[counter] = element.score
            counter++
        });

        this.label = this.name
    },
  };
  
  </script>