<template>
<ul class="navigation">  
  <li><a class="active" href="#home">Forside</a></li>  
  <li><a href="https://isdragen.dk/tournament/">Tournament</a></li>
  <li><a href="https://isdragen.dk/poll/index.php">Afstemning</a></li>
</ul>  
</template>

<script>
  export default {
  name: 'Navigation-component',
  components: {
  },
  props: {
  },
  methods: {
  }
  }
</script>

<style>
.navigation {
  margin: 0;
  padding: 0;
}

ul {  
  list-style-type: none;  
  margin: 0;  
  padding: 0px;  
  overflow: hidden;  
  background-color: black;  
}  
  
li {  
  float: left;  
}  
  
li a, .dropbtn {  
  display: block;  
  color: white;  
 font-size:20px;  
  text-align: center;  
  padding: 10px 20px;  
  text-decoration: none;  
}  
.active{  
background-color: #9AC791;  
color: white;  
}  
li a:hover , .dropdown:hover .dropbtn{  
  background-color: gray;  
  color: black;  
}  

.dropdown-content {  
  display: none;  
  position: absolute;  
  background-color: lightgrey;  
  min-width: 160px;  
  box-shadow: 5px 8px 10px 0px black;  
 }  
  
.dropdown-content a {  
  color: black;  
  padding: 12px 16px;  
  text-decoration: none;  
  display: block;  
  text-align: left;  
}  
  
.dropdown-content a:hover {  
background-color: gray;  
color:white;  
}  
  
.dropdown:hover .dropdown-content {  
  display: block;  
}  
</style>