<template>
<div class="select">
  <select class="standard-select" v-model="selected" @change="onChange($event)">
    <option disabled value="">Vælg gruppe</option>
    <option v-for="group in groups" v-bind:key="group.id" :value="group.id">{{group.name}}</option>
    <option value="0">Alle grupper</option>
  </select>

</div>  
</template>
  
  <script>
  import axios from 'axios'

  export default {
    name: 'group-dropdown',
    components: {
    },
    data() {
        return {
          groups: [],
          selected: ""
        };
    },
    props: {
        groupid: {
            type: String,
            default: "0"
        }
    },
    methods : {
      onChange(event) {
          this.$emit("group-change", event.target.value)
          console.log("Emit: " + event.target.value)
        }    
    },
    created: function() {   
      this.selected = this.groupid          
      axios.get("https://isdragen.dk/php/getinfo_groups.php").then(result => {
        var json = Object.values(result.data)
        this.groups = json
      })
    },
  };
  
  </script>

  <style scoped>

  </style>