<template>
  <div>
    <div class="select">
      <select class="standard-select" v-model="selected" @change="onChange($event)">
        <option disabled value="">Vælg dato</option>
        <option v-for="date in dates" v-bind:key="date.date" :value="date.date">{{date.date}}</option>
        <option value="0">Alle datoer</option>
      </select>
    
    </div>
  </div>  
</template>
      
      <script>
      import axios from 'axios'
    
      export default {
        name: 'date-dropdown',
        components: {
        },
        data() {
            return {
              dates: [],
              selected: ""
            };
        },
        props: {
            date: {
                type: String,
                default: ""
            },
            groupid: {
                type: String,
                default: "0"
            }
        },
        watch: { 
          groupid: function(newVal, oldVal) {
            if(newVal != oldVal) {
              this.getData()
            }
          }
        },
        methods : {
          onChange(event) {
              this.$emit("date-change", event.target.value)
          },
          getData() {
            console.log("test")
            this.selected = this.date          
            axios.get("https://isdragen.dk/php/getinfo_dates.php?group=" + this.groupid).then(result => {
              if(result.data != null){
                this.dates = Object.values(result.data)
              } else {
                console.log("No dates")
                this.dates = []
              }
            })
          }    
        },
        created: function() {   
          this.getData()
        },
      };
      
      </script>
    
      <style scoped>

      </style>