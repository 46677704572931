import Vue from 'vue'
import VueRouter from 'vue-router'
import app from '@/Main.vue'

Vue.use(VueRouter)

let routes=[
  {
      path: '/admin',
      name: 'admin',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../Admin.vue')
  },
  {
    path: '/',
    component: app
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
