<template>
    <div>
      <img src="./assets/banner.jpg"/> <br>
  
      <b-container class="center">
        <b-row>
            <b-col cols="12" sm="6" lg="6">
              <GroupDropdown 
                @group-change="selectGroup"
                v-bind:groupid="groupid">
              </GroupDropdown>
            </b-col>        
            <b-col cols="12" sm="6" lg="6">
              <DateSelect 
                @date-change="selectDate"
                v-bind:date="date"
                v-bind:groupid="groupid">
              </DateSelect>
  
            </b-col>        
        </b-row>
      </b-container>
  
      <Highscore 
        v-bind:date="date"
        v-bind:groupid="groupid"/>
      <GameView 
        v-bind:date="date" 
        v-bind:groupid="groupid"/>
      <br>
      <Bubble style="display:none" />
    </div>
  </template>
  
  <script>
  import Highscore from './components/Highscore.vue'
  import GameView from './components/GameView.vue'
  import Bubble from './components/Bubble.vue'
  import GroupDropdown from './components/GroupSelect.vue'
  import DateSelect from './components/DateSelect.vue'
  import axios from 'axios'
  
  export default {
    name: 'App',
    components: {
      Highscore, 
      Bubble,
      GameView,
      GroupDropdown,
      DateSelect
    },
    data() {
          return {
              groupid: "1",
              date: ""
          };
      },
    methods: {
      selectGroup(group){
        this.groupid = group
      },
      selectDate(date){
        this.date = date
        axios.get("https://isdragen.dk/php/getboardsbestplayers.php?date=" + date).then(result => {
          var json = Object.values(result.data)
          this.dates = json
        })
      }
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  
  select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 1em 0 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
    }
  .select {
    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid green;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
  }
  
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }
  </style>
  